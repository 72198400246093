import React from "react"
import { Typography, Row, Col, Button } from "antd"
import "./PrivacyPolicy.less"
import { Link } from "gatsby"

const { Title, Text } = Typography

export default function PrivacyPolicy() {
  return (
    <div className="privacyPolicyParent">
      <div className="privacyPolicyDiv">
        <Title className="title mainTitle">Privacy Policy</Title>
        <Text className="text">
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
          <br />
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The personal
          information that we collect is used for providing and improving the
          Service. Provided info is uploaded to our protected server at -
          <Link to="https://mentro.tech" target={"_blank"}>
            {" "}
            https://mentro.tech
          </Link>{" "}
          and it's associated subdomains and routes. We will not use or share
          your personal information with anyone except as described in this
          Privacy Policy.
          <br />
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at Mentro unless otherwise
          defined in this Privacy Policy.
        </Text>

        <Title className="title">Collection Of Information and Relevance</Title>
        <Text className="text">
          As a user you can explore our product(App and Website) with two major
          kind of access rights.
        </Text>
        <Title className="title">Access Mentro as A User</Title>
        <Text className="text">
          When you sign up to Mentro as a User, we take your email-id, name and
          password either from you or your Google Account. While regular usage
          of the app we might ask for an image for credibiliy, or the name of
          your college or organisation, which you would optionally provide us,
          for us to serve you a better experience.
          <br />
          In the regular usage of the app we might also pick up your location
          and preferences, on your consent to serve you a better experience.
          <br />
          We collect and store all personal information related to your App
          profile, to our internal service, which you voluntarily give us either
          upon sign-up or through continued use of the App.
        </Text>
        <Title className="title">Access Mentro as A Mentor</Title>
        <Text className="text">
          You get to use mentro as a mentor when you apply to be a mentor at our
          <Link to="https://mentro.tech/mentor" target={"_blank"}>
            {" "}
            mentor application form
          </Link>
          . We review your profile and grant you access to mentro as a mentor.
          When you get access to the app as a mentor, you are required to do the
          following:
        </Text>
        <ul>
          <li>
            Setup Google Calendar - Once you setup, one of our services will
            create a calendar called Mentro and add it to your calendars. All
            you mentorship events will be scheduled and added to that calendar.
          </li>
          <li>
            Setup your payouts - Provide, Either Account Number, Account Name
            and Account IFSC, or your UPI, for transaction. We in no way share
            your personal account information to any organisation.
          </li>
          <li>
            Edit your profile with your image, name, description or any relevant
            changes, on your personal choice.
          </li>
        </ul>
        <Title className="title marginTopTitle">Information Usage</Title>
        <Text className="text">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the App to:
        </Text>
        <ul>
          <li>
            Generate a personal profile about you to make future visits to the
            App more personalized.
          </li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            the App.
          </li>
          <li>Notify you of updates to the App or Site.</li>
          <li>Request feedback and contact you about your use of the App.</li>
          <li>To improve our website and product</li>
        </ul>
        <Text className="text">
          As a matter of policy, we do not sell or rent any of your personal
          information to third parties for their marketing purposes without your
          explicit consent.
          <br />
          You are able to view, change and remove your data associated with your
          profile. Should you choose to delete your account, please contact us
          at{" "}
          <a href="mailto:contact@mentro.tech" target={"_blank"}>
            contact@mentro.tech{" "}
          </a>
          , and we will follow up with such a request as soon as possible.
        </Text>
        <Title className="title">Log Data</Title>
        <Text className="text">
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </Text>
        <Title className="title">Cookies</Title>
        <Text className="text">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
          <br />
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </Text>
        <Title className="title">Security</Title>
        <Text className="text">
          We shall try to take all precautions to protect the Personal
          Information both online and offline. We will try to protect your
          information using technical and administrative security measures to
          reduce the risks of loss, misuse, unauthorized access, disclosure, and
          alteration. We have standard SSL certification which basically helps
          us create a secure connection between our server and user to render
          any information or action. Some of the safeguards we use are firewalls
          and data encryption, physical access controls to our data centers and
          information access authorization controls.
        </Text>
        <Title className="title">Links to Other Sites</Title>
        <Text className="text">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>
        <Title className="title">Children's Privacy</Title>
        <Text className="text">
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case we discover that a child under 13
          has provided us with personal information, we immediately delete this
          from our servers. If you are a parent or guardian and you are aware
          that your child has provided us with personal information, please
          contact us so that we will be able to do necessary actions.
        </Text>
        <Title className="title">Changes to This Privacy Policy</Title>
        <Text className="text">
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. This policy is effective as of 2021-07-08
        </Text>
        <Title className="title">Contact Us</Title>
        <Text className="text">
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at mentro.tech.org@gmail.com or
          contact@mentro.tech.
        </Text>
      </div>
    </div>
  )
}
